.footerHolder {
  height: 53.6px;
  max-height: 53.6px;
  display: flex;
  justify-content: space-between;

  padding: 1em 2em;

  width: 100%;
  /* background-color: #00afb9; */
  color: white;
  text-align: center;
  align-items: center;
  font-size: 13px !important;
  /* box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.25); */
}

.footerimgadj {
  /* height: 28px; */
  vertical-align: middle;

  height: 40px;
}

.widthLog {
  /* width: 35px; */
  width: 26px;
}

.paddingADJF {
  margin-left: 10px;
  color: #000;
}

.birdlogo {
  color: #cecece;
}

@media only screen and (max-width: 600px) {
  .birdlogo {
    display: none;
  }
  .paddingADJF {
    margin-left: 170px !important;
    color: #000;
  }
}
