html {
  font-size: 16px;
  box-sizing: border-box;
}
.MuiTypography-body1 {
  font-family: "Poppins", sans-serif !important;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url(../src/Poppins-Regular.ttf) format("truetype");
}
*,
*:before,
*:after {
  box-sizing: inherit;
  outline: 0;
}

body {
  margin: 0;
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Poppins", sans-serif !important;
  font-display: swap;
}
.MuiInputBase-input {
  font-family: "Poppins", sans-serif !important;
}
.mainHolder {
  min-height: calc(100vh - 93.6px);
}

.MuiFormControl-root {
  width: 100% !important;
}

.btn {
  color: #fff;
  border-radius: 8px;
  background: #4bd3c5;
  border: unset;
  padding: 1em 2em;
  width: 100%;
  cursor: pointer;
}

.loginGrid {
  padding: 4em 2em;
}
.MuiOutlinedInput-input {
  padding: 13.5px 14px !important;
}
a:visited {
  text-decoration: none;
  color: inherit;
}

a {
  text-decoration: none;
}
button {
  cursor: pointer;
}
.loaderAdj {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}
.MuiCircularProgress-colorPrimary {
  color: #4ed3c5 !important;
}
.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2em;
  margin-left: 1em;
}
.headerTitle {
  font-size: 24px;
  display: flex;
  justify-content: space-evenly;
  margin-top: -2.5em;
}
.headerlogo {
  width: 184px;
}

.headertext {
  width: 193px;
}

@media only screen and (max-width: 768px) {
  .headerTitle {
    font-size: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-top: -0.5em;
  }
}
@media only screen and (min-width: 340px) and (max-width: 700px) {
  .headerTitle {
    font-size: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-top: -0.5em;
  }
}
